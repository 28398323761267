import { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { MAP } from 'react-google-maps/lib/constants';
import { MarkerF } from '@react-google-maps/api';
import { paddingCalculationForClusters } from '../utils/paddingCalculationForClusters';
import empty from '../assets/empty.png';

class Clusters extends Component {
  render() {
    const { clusters, map } = this.props;

    if (_.isNil(clusters)) {
      return null;
    }

    let i = 0;

    let mapRefrenceInstances = null;
    if (map.context[MAP]) {
      mapRefrenceInstances = map.context[MAP];
    }

    const clusterOnClick = (centerPoint) => {
      mapRefrenceInstances.setCenter(centerPoint);
      mapRefrenceInstances.setZoom(map.getZoom() + 1);
    };

    return clusters.map((cluster) => {
      i += 1;
      return (
        <MarkerF
          key={i}
          position={{
            lat: cluster.Centroid.Latitude,
            lng: cluster.Centroid.Longitude,
          }}
          labelAnchor={new window.google.maps.Point(20, 0)}
          labelStyle={{
            border: '3px solid white',
            backgroundColor: '#00AD08',
            fontSize: '14px',
            fontWeight: 'bold',
            padding: paddingCalculationForClusters(cluster.ItemsCount),
            borderRadius: '50%',
          }}
          icon={{ url: empty }}
          onClick={() => {
            clusterOnClick({
              lat: cluster.Centroid.Latitude,
              lng: cluster.Centroid.Longitude,
            });
          }}
        >
          <span style={{ color: 'white' }}>{cluster.ItemsCount}</span>
        </MarkerF>
      );
    });
  }
}

const mapStateToProps = (state) => ({
  clusters: state.status.cluster,
});

export default connect(mapStateToProps)(Clusters);
