import {
  createBrandConfigRequestAction,
  createBrandConfigSuccessAction,
  createBrandConfigFailureAction,
} from './creators';
import { createCustomerLinksSuccessAction } from '../../../customer-links/action/creators';

const urlWithoutProtocol = (url) => {
  if (url.startsWith('http')) {
    const urlString = url.split('//');
    return urlString[1];
  }
  return url;
};

const environmentsEscapedDomain = [
  'test.poweredbyvirta.com',
  'poweredbyvirta.com',
];

const getBrandIdentifierByHostName = () => {
  const hostName = window.location.hostname;
  const match = environmentsEscapedDomain.find((escapedDomain) => {
    if (!escapedDomain) return false;

    const allowedDomainPattern = new RegExp(`^(?<brandIdentifier>[a-zA-Z0-9-]+)\\.${escapedDomain}$`);

    return allowedDomainPattern.test(hostName);
  });

  return match
    ? new RegExp(`^(?<brandIdentifier>[a-zA-Z0-9-]+)\\.${match}$`).exec(hostName)?.groups?.brandIdentifier
    : undefined;
};

// eslint-disable-next-line default-param-last
const fetchBrandConfig = () => async (dispatch) => {
  const brandIdentifier = getBrandIdentifierByHostName();
  if (!brandIdentifier) {
    return;
  }
  try {
    dispatch(createBrandConfigRequestAction());

    await fetch(`/file/${brandIdentifier}/platform/web/config.json`)
      .then((response) => {
        if (!response.ok || response.status !== 200) {
          throw new Error('Network response was not ok with fetching host information');
        }
        return response.json();
      }).then((config) => {
        dispatch(createBrandConfigSuccessAction(config));
        dispatch(createCustomerLinksSuccessAction({
          customerRegisterLink: `${urlWithoutProtocol(window.location.host)}/register`,
        }));
      });
  } catch (error) {
    console.log('There has been a problem with your fetch operation: ', error.message);
    dispatch(createBrandConfigFailureAction(error));
  }
};

export default fetchBrandConfig;
